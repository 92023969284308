<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Templates Attributes'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Templates Attributes Create'"
      :title="'Templates Attributes Create'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Submit</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :value="formDataToSend.name"
              :placeholder="'Name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>

          <p class="form-create__label">SEO Name (for URL)</p>
          <div class="input-container">
            <InputText
              :onChange="setSeoName"
              :id="'seoName'"
              :label="'seo name'"
              :value="formDataToSend.seo_name"
              :type="'text'"
              :placeholder="'SEO Name'"
              :error-messages="validationErrors['seoName']"
              isBordered
            />
          </div>

          <p class="form-create__label">Categories</p>
          <input
            v-model="categorySearch"
            class="form-create__search"
            type="text"
            placeholder="Search category..."
            @input="debouncedGetCategories"
          />

          <div class="input-container checkbox-container checkbox-container_margin">
            <div class="checkbox-container__item">
              <InputRadio
                :set-value="formDataToSend"
                :id="'category'"
                :values="categoriesList"
                :name="'fullName'"
                :value="'id'"
              />
            </div>
          </div>

          <div class="input-container">
            <MainCheckbox :label="'Active'" :id="'status'" :set-value="formDataToSend" />
          </div>
          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Submit</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import { seoTransform } from "~/helpers/seoTransform";
import ROUTE from "~/constants/routes";
import { debounce } from "lodash";
import categoriesApi from "~/api/categories";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";
import InputRadio from "~/components/atoms/inputs/InputRadio.vue";

export default {
  name: "TemplatesAttributesCreate",
  metaInfo: {
    title: "Templates Attributes Create",
  },
  data() {
    return {
      formDataToSend: {
        name: "",
        seo_name: "",
        status: false,
        category: null,
      },
      categoriesList: [],
      categorySearch: "",
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    MainCheckbox,
    InputRadio,
  },
  mounted() {
    this.getCategories();
  },
  computed: {
    ...mapState("references", ["error", "loadingProcess", "validationErrors"]),
    getFirstLink() {
      return ROUTE.TEMPLATES_ATTRIBUTES_LIST.replace(":page", 1);
    },
  },
  methods: {
    handleSubmit() {
      const data = {
        name: this.formDataToSend.name,
        seo_name: this.formDataToSend.seo_name,
        status: this.formDataToSend.status,
        category_id: this.formDataToSend.category,
      };

      this.$store.dispatch("references/createTemplatesAttributes", data);
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.TEMPLATES_ATTRIBUTES_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
      this.setSeoName(value);
    },
    setSeoName(data) {
      const value = seoTransform(data);
      this.formDataToSend.seo_name = value;
    },
    getCategories() {
      if (this.categorySearch.trim().length > 0) {
        const data = {
          search: this.categorySearch.trim(),
        };
        try {
          categoriesApi.getCategoriesListFilter("", data).then((res) => {
            const data = res.data.items.data;
            this.categoriesList = data;
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          categoriesApi.getCategoriesActive().then((res) => {
            const data = res.data.items;
            this.categoriesList = data;
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
    debouncedGetCategories: debounce(function () {
      this.getCategories();
    }, 300),
  },
};
</script>

<style lang="scss" scoped>
.form-create {
  &__search {
    font-size: 18px;
    margin-top: 20px;
    width: 400px;
    padding: 5px 10px;
  }
}
.input-select {
  max-width: 300px;
}
.checkbox-container {
  @include scrollBar;

  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;

  &_margin {
    margin-top: 20px;
  }
}
.checkbox-container__item {
  padding: 5px 0;
}
</style>
